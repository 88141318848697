
import { convertDecimalWithComma, formatDate } from "@/app/infrastructures/misc/Utils";
import { StoSCController } from "@/app/ui/controllers/StoSCController";
import debounce from "lodash/debounce";
import { Options, Vue } from "vue-class-component";
import { printManifestStoSC, printSummary } from "./modules/generate";
@Options({
  components: {},
  computed: {
    getTitle() {
      return this.$route.meta.title;
    },
  },
})
export default class TabStoSC extends Vue {

  unmounted() {
    StoSCController.setSearch("");
  }

  //print label
  printSummary(id: number) {
    printSummary(id);
  }

  //print manifest
  printManifest(id: number) {
    printManifestStoSC(id);
  }

  //Filter Search
  onSearch = debounce((value: string) => {
    StoSCController.setSearch(value);
    StoSCController.setFirstPage();
    this.fetchSTOSCList();
  }, 250);

  clearSearch() {
    StoSCController.setSearch("");
    this.fetchSTOSCList();
  }

  get filterSTOSC() {
    return StoSCController.filterSTOSC;
  }
  get isLoading() {
    return StoSCController.isLoading;
  }
  get errorCause() {
    return StoSCController.errCause;
  }
  get stoSCList() {
    return StoSCController.stoSCList;
  }
  get search() {
    return StoSCController.search;
  }

  formatDateCreated(date: string) {
    return formatDate(date);
  }

  get highlightedData() {
    const result: any = [];
    this.stoSCList.data.forEach((key, index) => {
      if (key.stoScId === this.bundleManifestId.stoScId) {
        result.push(index);
      }
    });
    return result;
  }

  get bundleManifestId() {
    return StoSCController.manifestIdSTOSC;
  }

  fetchSTOSCList() {
    StoSCController.fetchSTOSCList();
  }

  //table
  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-12 text-left",
        render: (_: any, index: any) => {
          return `<div class="text-left">${
            index +
            1 +
            this.stoSCList.pagination.limit * (this.stoSCList.pagination.page - 1)
          }</div>`;
        },
      },
      {
        name: "Id. Manifest",
        styleHead: "w-32 text-left",
        render: (item: any) => {
          return `<div class="text-left">${item.stoScNo}</div>`;
        },
      },
      {
        name: "Nama Sub Consolidator",
        styleHead: "w-56 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="text-left">${item.subConsolidatorName}</div>`;
        },
      },
      {
        name: "Total STT",
        styleHead: "w-24 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.totalSTT}</div>`;
        },
      },
      {
        name: "Total Koli",
        styleHead: "w-28 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.totalPiece}</div>`;
        },
      },
      {
        name: "Total Berat Kotor",
        styleHead: "w-44 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${convertDecimalWithComma(
            item.totalGrossWeight,
            2
          )} Kg</div>`;
        },
      },
      {
        name: "Total Berat Volume",
        styleHead: "w-44 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${convertDecimalWithComma(
            item.totalVolumeWeight,
            2
          )} Kg</div>`;
        },
      },
      {
        name: "Tanggal STO-SC",
        styleHead: "w-64 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="flex flex-col overflow-ellipsis text-left"><span>${this.formatDateCreated(
            item.stoScDate
          )}</span> <span class="text-xs ${
            item.createdName ? "text-gray-lp-500" : "text-white"
          }">${item.createdName || "User"}</span></div>`;
        },
      },
      {
        name: "Action",
        key: "button_column",
        styleHead: "w-24 text-left",
        styleButton: (item: any) => {
          return {
            outline: true,
            icon: "option-gray",
            title: "",
            textColor: "black",
            color: "gray-lp-400",
            customClass: "button-option",
            clickFunction: () => null,
            groupOptions: [
              {
                icon: "printer",
                label: "Cetak Label",
                clickFunction: () => this.printSummary(item.stoScId),
              },
              {
                icon: "printer",
                label: "Cetak Manifest",
                clickFunction: () => this.printManifest(item.stoScId),
              },
            ],
          };
        },
      },
    ];
  }
}
