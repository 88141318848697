import { StoSCRepositoryInteface } from "@/data/persistences/repositories/contracts/STOSCRepositoryInterface";
import { DetailSTOSC, ListSTOSC, ManifestIDSTOSC, RequestListStoSC } from "@/domain/entities/StoSC";
import ApiService from "../../services/ApiService";
import { StoSCMapper } from "@/data/persistences/mappers/StoSCMapper";
import { StoSCEndpoint } from "../../endpoints/hydra/StoSCEndpoint";
import { STOSCRequestInterface } from "@/data/payload/contracts/StoSCRequest";
import { ProcessToSTOSC } from "@/data/payload/api/StoSCRequest";

export class StoSCApiRepository implements StoSCRepositoryInteface {
    private readonly service: ApiService;
    private readonly mapper: StoSCMapper;
    private readonly endpoints: StoSCEndpoint;

    constructor(service: ApiService, mapper: StoSCMapper, endpoints: StoSCEndpoint) {
        this.service = service;
        this.mapper = mapper;
        this.endpoints = endpoints;
    }
    public async getListSTOSC(params: RequestListStoSC): Promise<ListSTOSC> {
        const resp = await this.service.invoke("get", this.endpoints.getListSTOSC(params), {});
        return this.mapper.convertListSTOSCFromApi(resp);
    }
    public async getDetailSTOSC(id: number): Promise<DetailSTOSC> {
        const resp = await this.service.invoke("get", this.endpoints.getDetailSTOSC(id), {});
        return this.mapper.convertDetailStoSCFromApi(resp);
    }
    public async updatetoSTOSC(payload: STOSCRequestInterface): Promise<ManifestIDSTOSC> {
        const resp = await this.service.invoke(
            "post",
            this.endpoints.updateToSTOSC(),
            undefined,
            payload as ProcessToSTOSC
        );
        return this.mapper.convertStoSCUpdateFromApi(resp);
    }

}