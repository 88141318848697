import { PaginationV2 } from "@/domain/entities/Pagination";
import { DetailSTOSC, ListSTOSC, ManifestIDSTOSC, StoSCDetailStt } from "@/domain/entities/StoSC";
import { AxiosResponse } from "axios";

export class StoSCMapper {
    public convertStoSCUpdateFromApi(res: AxiosResponse<any>): ManifestIDSTOSC {
        const { data } = res;
        return data.data?.sto_sc_id ? new ManifestIDSTOSC({ 
                stoScId: data.data.sto_sc_id, stoScNo: data.data.sto_sc_no
            }): new ManifestIDSTOSC()
    }

    public convertDetailStoSCFromApi(res: AxiosResponse<any>): DetailSTOSC {
        const { data } = res;
        return new DetailSTOSC({
            stoScId: data.data.sto_sc_id,
            subConsolidatorName: data.data.sub_consolidator_name,
            originSubConsolidator: data.data.origin_sub_consolidator,
            totalSTT: data.data.total_stt,
            totalPiece: data.data.total_piece,
            totalGrossWeight: data.data.total_gross_weight,
            totalVolumeWeight: data.data.total_volume_weight,
            createdName: data.data.created_name,
            stoScDate: data.data.sto_sc_date,
            updatedBy: data.data.updated_by,
            updatedAt: data.data.updated_at,
            stt: data.data.stt?.length ? data.data.stt.map((item: any) =>
                new StoSCDetailStt({
                    sttId: item.stt_id,
                    sttNo: item.stt_no,
                    sttProductType: item.stt_product_type,
                    sttTotalPiece: item.stt_total_piece,
                    sttOriginCity: `${item.stt_origin_city_id} - ${item.stt_origin_city_name}`,
                    sttDestCity: `${item.stt_destination_city_id} - ${item.stt_destination_city_id}`,
                    sttGrossWeight: item.stt_gross_weight,
                    sttVolumeWeight: item.stt_volume_weight,
                    sttChargeableWeight: item.stt_chargeable_weight,
                    sttCreatedAt: item.stt_created_at
                })
            ) : []
        })
    }

    public convertListSTOSCFromApi(res: AxiosResponse<any>): ListSTOSC {
        const { data } = res;
        return new ListSTOSC({
            pagination: new PaginationV2({
                page: data.meta.page,
                limit: data.meta.limit,
                totalData: data.meta.total_records
            }),
            data: data.data?.length ?
                data.data.map((key: any) =>
                    new DetailSTOSC({
                        stoScId: key.sto_sc_id,
                        stoScNo: key.sto_sc_no,
                        subConsolidatorName: key.sto_sc_partner_name,
                        originSubConsolidator: key.sto_sc_partner_code,
                        totalSTT: key.sto_sc_total_stt,
                        totalPiece: key.sto_sc_total_piece,
                        totalGrossWeight: key.sto_sc_total_gross_weight,
                        totalVolumeWeight: key.sto_sc_total_volume_weight,
                        createdName: key.sto_sc_created_name,
                        stoScDate: key.sto_sc_created_at,
                        updatedBy: key.sto_sc_updated_name,
                        updatedAt: key.sto_sc_updated_at
                    })
                ) : []
        })
    }
}