import { container } from "tsyringe";
import { StoSCApiRepository } from "../../repositories/api/StoSCApiRepository";
import ApiService from "../../services/ApiService";
import { StoSCMapper } from "@/data/persistences/mappers/StoSCMapper";
import { StoSCEndpoint } from "../../endpoints/hydra/StoSCEndpoint";
import { StoSCPresenter } from "@/app/ui/presenters/StoSCPresenter";

export class StoSCComponent {
    public static init() {
        container.register<StoSCApiRepository>(StoSCApiRepository, {
            useFactory: d => {
                return new StoSCApiRepository(
                    d.resolve(ApiService),
                    d.resolve(StoSCMapper),
                    d.resolve(StoSCEndpoint)
                );
            }
        });
        container.register<StoSCMapper>(StoSCMapper, {
            useClass: StoSCMapper
        });
        container.register<StoSCPresenter>(StoSCPresenter, {
            useFactory: d => {
                return new StoSCPresenter(d.resolve(StoSCApiRepository));
            }
        });
    }
}