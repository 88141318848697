import { StoSCApiRepository } from "@/app/infrastructures/repositories/api/StoSCApiRepository";
import { STOSCRequestInterface } from "@/data/payload/contracts/StoSCRequest";
import { DetailSTOSC, ListSTOSC, ManifestIDSTOSC, RequestListStoSC } from "@/domain/entities/StoSC";
import { injectable } from "tsyringe";

@injectable()
export class StoSCPresenter {
    private repository: StoSCApiRepository;
    constructor(repo: StoSCApiRepository) {
        this.repository = repo;
    }

    public updateToSTOSC(payload: STOSCRequestInterface): Promise<ManifestIDSTOSC> {
        return this.repository.updatetoSTOSC(payload);
    }

    public getDetailSTOSC(id: number): Promise<DetailSTOSC> {
        return this.repository.getDetailSTOSC(id);
    }

    public getListSTOSC(params: RequestListStoSC): Promise<ListSTOSC> {
        return this.repository.getListSTOSC(params);
    }
}