import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen w-full" }
const _hoisted_2 = { class: "flex justify-center h-screen overflow-auto" }
const _hoisted_3 = { class: "overflow-y-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (!_ctx.errorCause.length)
      ? (_openBlock(), _createBlock(_component_Search, {
          key: 0,
          onInput: _ctx.onSearch,
          onClear: _ctx.clearSearch,
          value: _ctx.search,
          class: "w-300px mt-6 mb-2",
          placeholder: "Cari Berdasarkan Id Manifest"
        }, null, 8, ["onInput", "onClear", "value"]))
      : _createCommentVNode("", true),
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_TableV2, {
          class: "mt-2",
          borderTop: false,
          isEmpty: !_ctx.stoSCList.data.length && !_ctx.isLoading,
          isFilter: !!_ctx.search && !!_ctx.filterSTOSC.startDate && !!_ctx.filterSTOSC.endDate,
          emptyMessage: "Belum ada STO-SC yang dapat ditampilkan",
          columns: _ctx.columns,
          data: _ctx.stoSCList.data,
          errorCause: _ctx.errorCause,
          onTryAgain: _ctx.fetchSTOSCList,
          loading: _ctx.isLoading,
          pagination: _ctx.stoSCList.pagination,
          "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.stoSCList.pagination = $event)),
          onRequest: _ctx.fetchSTOSCList,
          paginationStyle: "v3",
          highlightData: _ctx.highlightedData,
          totalColumnFreeze: 1,
          isRightFreeze: true,
          isPinnedScrollBottom: ""
        }, null, 8, ["isEmpty", "isFilter", "columns", "data", "errorCause", "onTryAgain", "loading", "pagination", "onRequest", "highlightData"])
      ])
    ])
  ]))
}