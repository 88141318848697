
import { Options, Vue } from "vue-class-component";
import {
  checkRolePermission,
  convertDecimalWithComma,
  formatDate,
  parsingErrorResponse,
} from "@/app/infrastructures/misc/Utils";
import { OUTGOING_SHIPMENT_STI_SC } from "@/app/infrastructures/misc/RolePermission";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import { StiSCController } from "@/app/ui/controllers/StiSCController";
import { ManifestIDSTOSC, PayloadUpdateToSTOSC, RequestListStoSC } from "@/domain/entities/StoSC";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { StoSCController } from "@/app/ui/controllers/StoSCController";
import { printSummary } from "./modules/generate";
/* eslint-disable @typescript-eslint/camelcase */

@Options({
  components: {
    EmptyState,
  },
  computed: {
    getTitle() {
      return this.$route.meta.title;
    },
    ableToCreate() {
      return checkRolePermission(OUTGOING_SHIPMENT_STI_SC.CREATE);
    },
  },
})
export default class TabProcessToSTOSC extends Vue {

  fetchSTISCList() {
    StiSCController.fetchStiSCList({isStoSc: true});
  }

  checkedData = [];

  get pagination() {
    return StiSCController.stiSCList.pagination;
  }

  // loading
  get isLoading() {
    return StiSCController.isLoading;
  }


  get errorCause() {
    return StiSCController.errorCause;
  }

  get stiSCList() {
    return StiSCController.stiSCList.data;
  }

  formatDateCreated(date: string) {
    return formatDate(date);
  }

  get bundleData() {
    return this.checkedData.map((key: any) => {
      return {
        manifest_id: key.stiSCId,
      };
    });
  }

  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-12 text-left",
        render: (item: any, index: any) => {
          return `<div class="text-left">${
            index + 1 + this.pagination.limit * (this.pagination.page - 1)
          }</div>`;
        },
      },
      {
        name: "Id. Manifest",
        styleHead: "w-32 text-left",
        render: (item: any) => {
          return `<div class="text-left">${item.stiSCId}</div>`;
        },
      },
      {
        name: "Nama Sub Consolidator",
        styleHead: "w-56 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="text-left">${item.stiSCPartnerName}</div>`;
        },
      },
      {
        name: "Total STT",
        styleHead: "w-24 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.stiSCTotalStt}</div>`;
        },
      },
      {
        name: "Total Koli",
        styleHead: "w-28 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.stiSCTotalPieces}</div>`;
        },
      },
      {
        name: "Total Berat Kotor",
        styleHead: "w-44 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${convertDecimalWithComma(
            item.stiSCTotalGrossWeight,
            2
          )} Kg</div>`;
        },
      },
      {
        name: "Total Berat Volume",
        styleHead: "w-44 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${convertDecimalWithComma(
            item.stiSCTotalVolumeWeight,
            2
          )} Kg</div>`;
        },
      },
      {
        name: "Tanggal STI-SC",
        styleHead: "w-64 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="flex flex-col overflow-ellipsis text-left"><span>${this.formatDateCreated(
            item.stiSCCreatedAt
          )}</span> <span class="text-xs ${
            item.stiSCCreatedName ? "text-gray-lp-500" : "text-white"
          }">${item.stiSCCreatedName || "User"}</span></div>`;
        },
      }
    ];
  }

  async updateToSTOSC() {
    const payload = new PayloadUpdateToSTOSC({ stiScList: this.bundleData });
    try {
      MainAppController.showLoading();
      MainAppController.closeMessageModal();
      const resp = await StoSCController.updateToStoSC(payload);
      StoSCController.setManifestIdSTOSC(new ManifestIDSTOSC({stoScId: resp.stoScId, stoScNo: resp.stoScNo}));
      if (resp.stoScNo.length) {
          printSummary(resp.stoScId);
      }
      this.$router.push({ name: "list-sto-sc" });
      StoSCController.setFilterSTOSCList(
      new RequestListStoSC({
        page: StoSCController.stoSCList.pagination.page,
        limit: StoSCController.stoSCList.pagination.limit,
        startDate: StoSCController.filterSTOSC.startDate,
        endDate: StoSCController.filterSTOSC.endDate
      })
    );
      StoSCController.fetchSTOSCList();
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(error, "Gagal Update ke STO-SC!", () => {
          this.updateToSTOSC();
          MainAppController.closeErrorMessage();
        })
      );
    } finally {
      MainAppController.closeLoading();
    }
  }
}
