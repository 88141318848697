import jsPDF from "jspdf";
import "jspdf-autotable";

const generateLabelPdfSTOSC = async (
    title: string,
    data: { name: string; value: any }[],
    logo: string
) => {
    const doc = new jsPDF("p", "mm", "a5", true);
    let y = 6;

    const pdfWidth = doc.internal.pageSize.width;
    const logoProperties = doc.getImageProperties(logo);
    const logoAspectRatio = logoProperties.height / logoProperties.width;

    const imageWidth = 40;
    const imageHeight = logoAspectRatio * 40;
    doc.addImage(
        logo,
        "PNG",
        pdfWidth - imageWidth - 9,
        y,
        imageWidth,
        imageHeight,
        "logo",
        "FAST",
        0
    );
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.setTextColor("#4d4d4d");

    y += 7;
    doc.text(title, 10, y);
    doc.setFillColor("#e0e0e0");

    y += 7;
    doc.rect(10, y, 190, 0.2, "F");
    doc.setFontSize(7);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(14);
    doc.setTextColor("#4d4d4d");

    for (const [index, item] of data.entries()) {
        y += index ? 17 : 13;

        doc.setFont("helvetica", "normal");
        doc.text(item.name, 10, y);
        doc.setFont("helvetica", "bold");
        doc.text(`: ${item.value}`, 70, y);
    }

    y = 175;
    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);
    doc.text("Station Admin", 10, y);
    doc.text("Kurir", 62, y);
    doc.text("Operations Supervisor", 97, y);

    y += 27;
    doc.setFontSize(8);
    doc.text("(................................)", 10, y);
    doc.text("(..........................................)", 50, y);
    doc.text("(..........................................)", 100, y);

    doc.setProperties({
        title
    });
    doc.autoPrint();
    window.open(doc.output("bloburl").toString(), "_blank");
};

export default generateLabelPdfSTOSC;
