import { PayloadUpdateToSTOSC } from "@/domain/entities/StoSC";
import { STOSCRequestInterface } from "../contracts/StoSCRequest";
import { ConvertObjectCamelToSnakeCase } from "@/app/infrastructures/misc/Utils";

export class ProcessToSTOSC implements STOSCRequestInterface {
    payload: PayloadUpdateToSTOSC = new PayloadUpdateToSTOSC();
    constructor(fields?: Partial<PayloadUpdateToSTOSC>) {
        Object.assign(this.payload, fields);
    }
    public toJSON(): string {
        return JSON.stringify(ConvertObjectCamelToSnakeCase(this.payload));
    }

}