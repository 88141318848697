import { QueryParamsEntities } from "./MainApp";
import { PaginationV2 } from "./Pagination";


export class PayloadUpdateToSTOSC {
    stiScList: any = [];
    constructor(fields?: Partial<PayloadUpdateToSTOSC>) {
        Object.assign(this, fields);
    }
}

export class ManifestIDSTOSC {
    stoScNo = "";
    stoScId = 0;
    constructor(fields?: Partial<ManifestIDSTOSC>) {
        Object.assign(this, fields);
    }
}

export class DetailSTOSC {
    stoScId = 0;
    stoScNo = "";
    subConsolidatorName = "";
    originSubConsolidator = "";
    totalSTT = 0;
    totalPiece = 0;
    totalGrossWeight = 0;
    totalVolumeWeight = 0;
    createdName = "";
    stoScDate = "";
    updatedBy = "";
    updatedAt = "";
    stt: StoSCDetailStt[] = []
    constructor(fields?: Partial<DetailSTOSC>) {
        Object.assign(this, fields);
    }
}

export class StoSCDetailStt {
    sttId = 0;
    sttNo = "";
    sttProductType = "";
    sttTotalPiece = 0;
    sttOriginCity = "";
    sttDestCity = "";
    sttGrossWeight = 0;
    sttVolumeWeight = 0;
    sttChargeableWeight = 0;
    sttCreatedAt = "";
    constructor(fields?: Partial<StoSCDetailStt>) {
        Object.assign(this, fields);
    }
}

export class RequestListStoSC {
    startDate = "";
    endDate = "";
    stoScNo = "";
    page = 1;
    limit = 20;
    constructor(fields?: Partial<RequestListStoSC>) {
        Object.assign(this, fields);
    }
    toQueryString(): string {
        return new QueryParamsEntities(this).queryString;
    }
}
export class ListSTOSC {
    pagination = new PaginationV2();
    data: DetailSTOSC[] = [];
    constructor(fields?: Partial<ListSTOSC>) {
        Object.assign(this, fields);
    }
}