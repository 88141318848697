
import {
  convertDecimalWithComma, ellipsisString,
  formatDate
} from "@/app/infrastructures/misc/Utils";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { StoSCController } from "@/app/ui/controllers/StoSCController";
import generateLabelPdfSTOSC from "../modules/index";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { generateManifest2ColumnPotrait } from "@/app/ui/modules/manifest-v2/potrait";
import { ColumnManifestData, SectionManifestData, HeaderManifestData } from "@/domain/entities/MainApp";
import { StoSCDetailStt } from "@/domain/entities/StoSC";

const dataProfile = () => {
  return AccountController.accountData;
}
const roleAccount = () => {
  return (
    (dataProfile().account_type === "partner"
      ? dataProfile().account_type_detail.type
      : dataProfile().account_type) || "internal"
  );
}

export const printSummary = async (id: number) => {
  const logo = await import(`@/app/ui/assets/images/logo-${roleAccount()}.png`);
  const printManifest = await StoSCController.getDetailSTOSC({ id, isPrint: true });
  if (printManifest) {
    const dataHead = StoSCController.detailSTOSC;
    const fields = [
      {
        name: "Total STT",
        value: dataHead.totalSTT,
      },
      {
        name: "Total Koli",
        value: dataHead.totalPiece,
      },
      {
        name: "Total Berat",
        value: `${convertDecimalWithComma(dataHead.totalGrossWeight, 2)} Kg`,
      },
      {
        name: "Total Berat Volume",
        value: `${convertDecimalWithComma(dataHead.totalVolumeWeight, 2)} Kg`,
      },
      {
        name: "Tanggal STO-SC",
        value: formatDate(dataHead.stoScDate)?.replace(/,/g, " -"),
      },
      {
        name: "Diperbarui Oleh",
        value: ellipsisString(dataHead.updatedBy, 21),
      },
    ];
    generateLabelPdfSTOSC(
      `${ellipsisString(dataProfile().name, 21)} 'STO-SC${dataHead.stoScId}'`,
      fields,
      logo.default
    );
  }
  MainAppController.closeLoading();
}

export const printManifestStoSC = async (id: number) => {
  const isSuccessPrint = await StoSCController.getDetailSTOSC({ id, isPrint: true });
  if (isSuccessPrint) {
    const dataManifest = StoSCController.detailSTOSC;
    const data = new SectionManifestData({
      featureName: `STO-SC${dataManifest.stoScId}`,
      headerDetailData: new HeaderManifestData({
        totalStt: dataManifest.totalSTT,
        totalPiece: dataManifest.totalPiece,
        totalGrossWeight: dataManifest.totalGrossWeight,
        totalVolumeWeight: dataManifest.totalVolumeWeight,
        updatedAt: dataManifest.updatedAt,
        updatedBy: dataManifest.updatedBy
      }),
      headerSection: [
        'accountTypeName',
        'totalStt',
        'totalPiece',
        'totalGrossWeight',
        'totalVolumeWeight',
        'updatedAt',
        'updatedBy'
      ],
      data: dataManifest.stt.map(
        (item: StoSCDetailStt) =>
          new ColumnManifestData({
            sttNo: item.sttNo,
            totalGrossWeight: item.sttGrossWeight,
            totalPiece: item.sttTotalPiece
          })
      ),
      totalColumnHeader: 3,
      signature: true
    });
    generateManifest2ColumnPotrait(data);
  }
  MainAppController.closeLoading();
}

