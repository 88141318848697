import { ProcessToSTOSC } from "@/data/payload/api/StoSCRequest";
import { DetailSTOSC, ListSTOSC, ManifestIDSTOSC, PayloadUpdateToSTOSC, RequestListStoSC } from "@/domain/entities/StoSC";
import store from "@/store";
import { container } from "tsyringe";
import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";
import { StoSCPresenter } from "../presenters/StoSCPresenter";
import { StoSCComponent } from "@/app/infrastructures/dependencies/modules/StoSCComponent";
import { MainAppController } from "./MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
StoSCComponent.init();

export interface StoSCState {
    manifestIdSTOSC: ManifestIDSTOSC;
}
@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: "sto-sc"
})
class StoSCStore extends VuexModule implements StoSCState {
    public manifestIdSTOSC: ManifestIDSTOSC = new ManifestIDSTOSC();
    public filterSTOSC: RequestListStoSC = new RequestListStoSC({ startDate: '', endDate: '' })
    public detailSTOSC: DetailSTOSC = new DetailSTOSC();
    public stoSCList: ListSTOSC = new ListSTOSC();
    public isLoading = false;
    public errCause = "";
    public search = "";
    @Action
    public updateToStoSC(params: PayloadUpdateToSTOSC) {
        const presenter = container.resolve(StoSCPresenter);
        return presenter.updateToSTOSC(new ProcessToSTOSC({ stiScList: params.stiScList }));
    }

    @Action
    public getListSTOSC(params: RequestListStoSC) {
        this.setLoading(true);
        const presenter = container.resolve(StoSCPresenter);
        return presenter.getListSTOSC(params)
            .then((res: ListSTOSC) => {
                this.setListSTOSC(res);
                this.setErrCause("");
                if (this.stoSCList.data.length) {
                    setTimeout(() => {
                        this.setManifestIdSTOSC(new ManifestIDSTOSC());
                    }, 5000);
                }
            })
            .catch((error: any) => {
                this.setErrCause(error.response ? "server" : "internet");
            }).finally(() => {
                this.setLoading(false);
            });
    }

    @Action
    public fetchSTOSCList() {
        this.getListSTOSC(
            new RequestListStoSC({
                page: this.stoSCList.pagination.page,
                limit: this.stoSCList.pagination.limit,
                startDate: this.filterSTOSC.startDate ? new Date(this.filterSTOSC.startDate).toLocaleDateString("fr-CA") : "",
                endDate: this.filterSTOSC.endDate ? new Date(this.filterSTOSC.endDate).toLocaleDateString("fr-CA") :  "",
                stoScNo: this.search,
            })
        );
    }

    @Action
    public getDetailSTOSC(params: { id: number, isPrint: boolean }) {
        MainAppController.showLoading();
        MainAppController.closeMessageModal();
        const presenter = container.resolve(StoSCPresenter);
        return presenter.getDetailSTOSC(params.id)
            .then((res: DetailSTOSC) => {
                this.setDetailSTOSC(res);
                return true;
            }).catch((err: any) => {
                MainAppController.showErrorMessage(parsingErrorResponse(err, `${params.isPrint ? 'Gagal Print!' : 'Gagal Get Detail STO-SC!'}`, () => {
                    this.getDetailSTOSC({ id: params.id, isPrint: params.isPrint });
                    MainAppController.closeErrorMessage();
                }))
                return false;
            }).finally(() => MainAppController.closeLoading());
    }

    @Mutation
    public setManifestIdSTOSC(params: ManifestIDSTOSC) {
        this.manifestIdSTOSC = params;
    }

    @Mutation
    public setFilterSTOSCList(params: RequestListStoSC) {
        this.filterSTOSC = params;
    }

    @Action
    public setFirstPage() {
        this.stoSCList.pagination.page = 1;
    }

    @Mutation
    public setDetailSTOSC(params: DetailSTOSC) {
        this.detailSTOSC = params;
    }

    @Mutation
    public setListSTOSC(params: ListSTOSC) {
        this.stoSCList = params;
    }

    @Mutation
    public setLoading(param: boolean) {
        this.isLoading = param;
    }

    @Mutation
    public setErrCause(param: string) {
        this.errCause = param;
    }

    @Mutation
    public setSearch(param: string) {
        this.search = param;
    }

}
export const StoSCController = getModule(StoSCStore);
