import { RequestListStoSC } from "@/domain/entities/StoSC";

export class StoSCEndpoint {
    updateToSTOSC(): string {
        return "/hydra/v1/sto-sc/update";
    }
    
    getDetailSTOSC(id: number): string {
        return `/hydra/v1/sto-sc/detail/${id}`
    }

    getListSTOSC(params: RequestListStoSC) {
        return `/hydra/v1/sto-sc?${params.toQueryString()}`
    }
}