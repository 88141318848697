import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "relative bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen w-full" }
const _hoisted_2 = { class: "flex flex-col justify-between items-start border-b border-gray-lp-400 z-20 relative py-6" }
const _hoisted_3 = { class: "flex flex-row align-middle items-center justify-between w-full" }
const _hoisted_4 = { class: "text-20px text-black-lp-200 flex flex-row items-center flex-no-wrap relative" }
const _hoisted_5 = {
  class: "mr-8 font-semibold",
  style: {"align-self":"center"}
}
const _hoisted_6 = { class: "flex gap-x-6 text-20px border-b border-gray-lp-400 pt-6" }
const _hoisted_7 = { class: "py-2 font-normal text-gray-lp-300 font-montserrat w-full flex flex-col text-12px" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_time_range_picker = _resolveComponent("time-range-picker")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, [
          _createVNode("span", _hoisted_5, _toDisplayString(_ctx.getTitle), 1),
          _createVNode(_component_time_range_picker, {
            startDate: _ctx.periodeStart,
            endDate: _ctx.periodeEnd,
            onSelect: _ctx.setPeriode,
            default: "7 hari terakhir",
            class: "ml-2",
            ref: "datePicker"
          }, null, 8, ["startDate", "endDate", "onSelect"])
        ])
      ])
    ]),
    _createVNode("div", _hoisted_6, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
        return (_openBlock(), _createBlock("div", {
          key: tab.name,
          class: `flex flex-row items-center justify-center self-center
        ${
          _ctx.isTabActive(tab.name)
            ? 'text-red-lp-100 border-b-2 border-red-lp-100'
            : 'text-gray-lp-600'
        }`
        }, [
          _createVNode("button", {
            onClick: ($event: any) => (_ctx.changeTabs(tab.name)),
            class: `pb-4 font-semibold hover:text-red-lp-100 focus:outline-none`
          }, _toDisplayString(tab.title), 9, ["onClick"]),
          _createVNode("img", {
            src: 
            require(`@/app/ui/assets/svg/${
              tab.isActiveTooltip ? 'info-red' : 'info'
            }.svg`)
          ,
            class: "ml-3 mb-4 h-auto xxl:h-5 cursor-pointer",
            alt: "info",
            onMouseenter: ($event: any) => (_ctx.toggleTooltip($event, index)),
            onMouseleave: ($event: any) => (_ctx.toggleTooltip($event, index))
          }, null, 40, ["src", "onMouseenter", "onMouseleave"])
        ], 2))
      }), 128)),
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
        return (_openBlock(), _createBlock(_component_OverlayPanel, {
          ref: "opTooltip",
          key: tab.name,
          appendTo: "body",
          class: "w-1/5"
        }, {
          default: _withCtx(() => [
            _createVNode("div", _hoisted_7, _toDisplayString(!_ctx.tabs[index].isActiveTooltip
              ? "Daftar paket dengan status STI-SC yang siap di proses ke STO-SC"
              : "Paket telah diserahkan oleh POS Cabang Utama/Sub consolidator ke Shuttle"), 1)
          ]),
          _: 2
        }, 1536))
      }), 128))
    ]),
    _createVNode(_component_router_view)
  ]))
}