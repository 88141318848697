
/* eslint-disable @typescript-eslint/camelcase */
import { Vue, Options } from "vue-class-component";
import { checkRolePermission } from "@/app/infrastructures/misc/Utils";
import { CARGO } from "@/app/infrastructures/misc/RolePermission";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import OverlayPanel from "primevue/overlaypanel";
import { StiSCController } from "@/app/ui/controllers/StiSCController";
import { StoSCController } from "@/app/ui/controllers/StoSCController";
import { RequestListStoSC } from "@/domain/entities/StoSC";
@Options({
  components: {
    EmptyState,
    OverlayPanel,
  },
  computed: {
    getTitle() {
      return this.$route.meta.name;
    },
    ableToCreate() {
      return checkRolePermission(CARGO.CREATE);
    },
    ableToViewDetail() {
      return checkRolePermission(CARGO.DETAIL);
    },
  },
})
export default class Cargo extends Vue {
  // Tabs
  tabs = [
    {
      name: "process-to-sto-sc",
      title: "Proses ke STO-SC",
      isActiveTooltip: false,
      tooltip: "",
    },
    { name: "list-sto-sc", title: "STO-SC", isActiveTooltip: false, tooltip: "" },
  ];

  changeTabs(value: string) {
    (this.$refs.datePicker as any)?.onSelectOption("7 hari terakhir", false);
    this.$router.push({
      name: value,
    });
    if (value.includes("process-to-sto-sc")) {
      StiSCController.setPeriodeStart(this.periodeStart);
      StiSCController.setPeriodeEnd(this.periodeEnd);
      StiSCController.setFirstPage();
      StiSCController.fetchStiSCList({isStoSc: true});
    } else {
      StoSCController.setFilterSTOSCList(
        new RequestListStoSC({
          startDate: this.periodeStart,
          endDate: this.periodeEnd,
          stoScNo: StoSCController.filterSTOSC.stoScNo,
        })
      );
      StoSCController.setFirstPage();
      StoSCController.fetchSTOSCList();
    }
  }

  isActiveTooltip = false;
  toggleTooltip(event: any, index: number) {
    this.tabs[index].isActiveTooltip = !this.tabs[index].isActiveTooltip;
    const refs: any = this.$refs;
    refs["opTooltip"].toggle(event);
  }

  isTabActive(name: string) {
    return name === this.$route.name;
  }

  unmounted() {
    this.periodeStart = "";
    this.periodeEnd = "";
    StiSCController.setPeriodeStart("" as any);
    StiSCController.setPeriodeEnd("" as any);
    StoSCController.setSearch("");
    StiSCController.setFirstPage();
  }

  fetchSTISC(name: string) {
    if (name.includes("process-to-sto-sc")) {
      StiSCController.setPeriodeStart(this.periodeStart);
      StiSCController.setPeriodeEnd(this.periodeEnd);
      StiSCController.setFirstPage();
      StiSCController.fetchStiSCList({isStoSc: true});
      return;
    }
    StoSCController.setFilterSTOSCList(
      new RequestListStoSC({
        page: StoSCController.stoSCList.pagination.page,
        limit: StoSCController.stoSCList.pagination.limit,
        startDate: this.periodeStart,
        endDate: this.periodeEnd,
        stoScNo: StoSCController.filterSTOSC.stoScNo,
      })
    );
    StoSCController.setFirstPage();
    StoSCController.fetchSTOSCList();
  }

  // filter date
  periodeStart: any = "";
  periodeEnd: any = "";

  setPeriode(value: Array<any>, isFetch: boolean) {
    this.periodeStart = value[0];
    this.periodeEnd = value[1];
    if (value[0] && value[1] && isFetch) {
      this.fetchSTISC(this.$route.path);
    }
  }
}
